import React, {useRef, useEffect} from 'react'
import classes from './ImgBanner.module.css'
import Card from '../../hoc/Card/Card'
import arrowAnimationData from '../../assets/lotties/lottieflow-arrow-05-2-ffffff-easey.json'
import lottie from 'lottie-web'
import {useMediaQuery} from '../../hooks'

const ImgBanner = ({ phoneNumber }) => {

    const arrowLottieRef = useRef();
    const isDesktop = useMediaQuery('(min-width: 768px)');

    useEffect(() => {
        if (!arrowLottieRef.current) return;

        lottie.loadAnimation({
            container: arrowLottieRef.current, // the dom element
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: arrowAnimationData, // the animation data
        });
    }, [])

    const handleScrollDown = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className={classes.ImgBanner}>
            <div className={classes.Title}>
                <h2>Pre-Certification <br/> Self-Service Portal</h2>
                <p>See all the requirements and how to start a new pre-certification request&nbsp;&nbsp;<span ref={arrowLottieRef} onClick={handleScrollDown} className={classes.ArrowLottie}/></p>
            </div>
            <Card style={styles.container(isDesktop)}>
                <h3>For additional support, call Accolade Provider Services</h3>
                <p><a href={`tel:${phoneNumber}`}
                      className={classes.PhoneNumberLink}>{phoneNumber}</a></p>
                <p className={classes.Text}>Speak to a Provider Health Assistant about eligibility, benefits employer
                    sponsored programs or precertification questions.</p>
            </Card>
        </div>
    );
};

const styles = {
    container: isDesktop => ({
        width: isDesktop ? '380px':'initial'
    })
  };

export default ImgBanner
