import React from 'react';
import classes from './Header.module.css';
import {ALLOWED_ROUTES} from '../../constants';
import AAALogo from '../../assets/images/AAA.png';
import AmplifyLogo from '../../assets/images/amplify-logo.png';
import ArcfieldLogo from '../../assets/images/arcfield-logo.png';
import boartLogo from '../../assets/images/boart.png';
import FafLogo from '../../assets/images/FA.png';
import OriglioLogo from '../../assets/images/origlio-logo.png';
import HEBREWLogo from '../../assets/images/Hebrew.png';
import HonickmanLogo from '../../assets/images/honickman-logo.png';
import HunterLogo from '../../assets/images/hunter-douglas.png';
import CeteraLogo from '../../assets/images/cetera_logo.png';
import CofwLogo from '../../assets/images/cityoffortworth_logo.png';
import CogentrixLogo from '../../assets/images/cogentrix_logo.png';
import CROWLEYLogo from '../../assets/images/Crowley_logo.png';
import KindredLogo from '../../assets/images/Kindred.png';
import RscLogo from '../../assets/images/RSC.png';
import SAFEAUTOLogo from '../../assets/images/safe-auto-logo.png';
import SauderLogo from '../../assets/images/sauder-logo.png';
import BeaconLogo from '../../assets/images/beacon-logo.png';
import GraymonLogo from '../../assets/images/graymont.png';
import LermanHoldingsLogo from '../../assets/images/lerman-logos.png';
import TKWWLogo from '../../assets/images/tkww.png';
import LassondeLogo from '../../assets/images/lassonde.png';
import PeratonLogo from '../../assets/images/peraton.png';
import HyattLogo from '../../assets/images/hyatt.png';
import RevereLogo from '../../assets/images/revere-plastics.png';
import WagmanLogo from '../../assets/images/wagman-logo.png';
import GreshamLogo from '../../assets/images/greshamLogo.png';
import ToryBurchLogo from '../../assets/images/toryBurchLogo.png';
import RedThreadLogo from '../../assets/images/redthread.png';
import PayPalLogo from '../../assets/images/paypal.png';
import AccoladeLogo from '../../assets/images/accolade_logo.svg';
import CbizLogo from '../../assets/images/cbiz.png';
import GalloLogo from '../../assets/images/gallo-winery.png'
import ChoiceHotelsLogo from '../../assets/images/choicehotels.png';
import ApptioLogo from '../../assets/images/apptio-logo.png';
import SevenElevenLogo from '../../assets/images/7-eleven-brand-family.png';
import SafranLogo from '../../assets/images/safran.png';
import NGMLogo from '../../assets/images/nevada-gold-mines.png';
import HilliardLogo from '../../assets/images/hcs.png';
import UnisysLogo from '../../assets/images/unisys.png';
import LandrysLogo from '../../assets/images/landrys.png';
import GreifLogo from '../../assets/images/greif.png';
import RevSpringLogo from '../../assets/images/revspring.png';
import SimpliSafeLogo from '../../assets/images/simplisafe.png';
import UCalLogo from '../../assets/images/ucal.png';
import Gentiva from '../../assets/images/gentiva.png';
import StandardAero from '../../assets/images/standardaero-min.png';
import EnlyteLogo from '../../assets/images/enlyte-logo.png';
import EnerconLogo from '../../assets/images/enercon-logo.png';
import RedRiverTechnologyLogo from "../../assets/images/red-river-technology-logo.png";
import LeadVentureLogo from "../../assets/images/leadventure-logo.png";
import PTAPlastics from '../../assets/images/pta.png';
import RocketSoftware from '../../assets/images/rocket-software.png';
import DwyerOmega from '../../assets/images/dwyer-omega.png';
import L3Harris from "../../assets/images/l3harris.png";
import PediatricAssociates from '../../assets/images/pediatric-associates.png';

const Header = ({currentRoute}) => (
    <header className={classes.Header}>
        <img src={AccoladeLogo} alt="Accolade Logo" className={[classes.Logo, classes.AccoladeLogo].join(' ')}/>

        {currentRoute === ALLOWED_ROUTES.KINDRED && (
            <img src={KindredLogo} alt="Kindred Logo" className={[classes.Logo, classes.KindredLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.AAA && (
            <img src={AAALogo} alt="AAA Logo" className={[classes.Logo, classes.AAALogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.BEACON && (
            <img src={BeaconLogo} alt="Beacon Logo" className={[classes.Logo, classes.BeaconLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.HEBREW && (
            <img src={HEBREWLogo} alt="Hebrew Logo" className={[classes.Logo, classes.HebrewLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.CROWLEY && (
            <img src={CROWLEYLogo} alt="Crowley Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.SAFEAUTO && (
            <img src={SAFEAUTOLogo} alt="safeAuto Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.ORIGLIO && (
            <img src={OriglioLogo} alt="Origlio Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.SAUDER && (
            <img src={SauderLogo} alt="Sauder Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.TKWW && (
            <img src={TKWWLogo} alt="TKWW Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.CETERA && (
            <img src={CeteraLogo} alt="Cetera Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.COGENTRIX && (
            <img src={CogentrixLogo} alt="Cogentrix Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.CITY_OF_FORT_WORTH && (
            <img src={CofwLogo} alt="Cofw Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.FIRST_AMERICAN_FIN && (
            <img src={FafLogo} alt="Faf Logo" className={[classes.Logo, classes.FafLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.HUNTER_DOUGLAS && (
            <img src={HunterLogo} alt="Hunter Douglas Logo" className={[classes.Logo, classes.HebrewLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.HONICKMAN && (
            <img src={HonickmanLogo} alt="Honickman Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.REMEDI_SENIOR_CARE && (
            <img src={RscLogo} alt="Rsc Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.BOART_LONGYEAR && (
            <img src={boartLogo} alt="boart Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.AMPLIFY && (
            <img src={AmplifyLogo} alt="Amplify Logo" className={[classes.Logo, classes.AmplifyLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.ARCFIELD && (
            <img src={ArcfieldLogo} alt="Arcfield Logo" className={[classes.Logo, classes.ArcfieldLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.PERATON && (
            <img src={PeratonLogo} alt="PeratonLogo Logo" className={[classes.Logo, classes.PeratonLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.GRAYMONT && (
            <img src={GraymonLogo} alt="Graymont Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.HYATT_HOTELS_CORPORATION && (
            <img src={HyattLogo} alt="Hyatt Hotels Corporation Logo"
                 className={[classes.Logo, classes.HyattLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.LASSONDE && (
            <img src={LassondeLogo} alt="Lassonde Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.REVERE_PLASTICS && (
            <img src={RevereLogo} alt="Revere Plastics Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.WAGMAN && (
            <img src={WagmanLogo} alt="Wagman Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.BOART_LONGYEAR && (
            <img src={boartLogo} alt="boart Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.GRESHAM && (
            <img src={GreshamLogo} alt="Gresham Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.TORY_BURCH && (
            <img src={ToryBurchLogo} alt="Tory Burch Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.LERMAN_HOLDINGS && (
            <img src={LermanHoldingsLogo} alt="Lerman Holdings Logo"
                 className={[classes.Logo, classes.LermanHoldingsLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.RED_THREAD && (
            <img src={RedThreadLogo} alt="RedThread Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.PAYPAL && (
            <img src={PayPalLogo} alt="PayPal Logo" className={[classes.Logo, classes.PaypalLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.CBIZ && (
            <img src={CbizLogo} alt="CBIZ Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.CHOICE_HOTELS && (
            <img src={ChoiceHotelsLogo} alt="Choice Hotels Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.GALLO_WINERY && (
            <img src={GalloLogo} alt="Gallo Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.APPTIO && (
            <img src={ApptioLogo} alt="Apptio Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.SEVEN_ELEVEN && (
            <img src={SevenElevenLogo} alt="7-Eleven Logo"
                 className={[classes.Logo, classes.SevenElevenLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.SAFRAN && (
            <img src={SafranLogo} alt="Safran Logo" className={[classes.Logo, classes.SevenElevenLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.NEVADA_GOLD_MINES && (
            <img src={NGMLogo} alt="Nevada Gold Mines Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.HILLIARD && (
            <img src={HilliardLogo} alt="Hilliard Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.UNISYS && (
            <img src={UnisysLogo} alt="Unisys Logo" className={[classes.Logo, classes.SevenElevenLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.LANDRYS && (
            <img src={LandrysLogo} alt="Landry's, Inc. Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.GREIF && (
            <img src={GreifLogo} alt="Greif INC Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.REV_SPRING && (
            <img src={RevSpringLogo} alt="RevSpring Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.SIMPLI_SAFE && (
            <img src={SimpliSafeLogo} alt="SimpliSafe Logo" className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.UNIVERSITY_CALIFORNIA && (
            <img src={UCalLogo} alt="University of California Logo"
                 className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.GENTIVA && (
            <img src={Gentiva} alt="Gentiva Logo"
                 className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.STANDARD_AERO && (
            <img src={StandardAero} alt="StandardAero Logo"
                 className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.ENLYTE && (
            <img src={EnlyteLogo} alt="Enlyte Logo"
                 className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.ENERCON && (
            <img src={EnerconLogo} alt="Enercon Logo"
                 className={[classes.Logo, classes.EnerconLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.RED_RIVER_TECHNOLOGY && (
            <img src={RedRiverTechnologyLogo} alt="Red River Technology Logo"
                 className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.LEAD_VENTURE && (
            <img src={LeadVentureLogo} alt="LeadVenture Logo"
                className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.PTAPLASTICS && (
            <img src={PTAPlastics} alt="PTA Plastics Logo"
                 className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.ROCKETSOFTWARE && (
            <img src={RocketSoftware} alt="Rocket Software Logo"
                 className={[classes.Logo, classes.RocketSoftwareLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.DWYEROMEGA && (
            <img src={DwyerOmega} alt="Dwyer Omega Logo"
                 className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.L3HARRIS && (
            <img src={L3Harris} alt="L3Harris Logo"
                className={[classes.Logo, classes.L3Harris].join(' ')}/>
        )}

        {currentRoute === ALLOWED_ROUTES.PEDIATRIC_ASSOCIATES && (
            <img src={PediatricAssociates} alt="Pediatric Associates Logo"
                className={[classes.Logo, classes.DefaultLogo].join(' ')}/>
        )}

    </header>


)

export default Header
