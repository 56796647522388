import React from 'react'
import classes from './Button.module.css'

const Button = ({children, id, onClick, style, type="button"}) => (
        <button className={classes.Button} style={style} onClick={onClick} id={id} type={type}>
            {children}
        </button>
);

export default Button
