import React from 'react'
import Aux from '../Aux-component'
import classes from './Card.module.css'

const Card = ({children, style}) => (
    <Aux>
        <div className={classes.Card} style={style}>
            {children}
        </div>
    </Aux>
);

export default Card
