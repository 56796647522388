import React, {useEffect} from 'react'
import Card from '../../hoc/Card/Card'
import Banner from '../../hoc/Banner/Banner'
import Button from '../../hoc/Button/Button'
import Aux from '../../hoc/Aux-component'
import classes from '../../Global.module.css'
import Accordion from '../../components/Accordion/Accordion'
import {useMediaQuery} from '../../hooks'

const LighthouseAutismCenter = () => {

    const isDesktop = useMediaQuery('(min-width: 768px)');

    useEffect(() => {
        document.title = "Precert Accolade | Lighthouse Autism Center & Missing Piece";
    }, []);
    return (
        <Aux>
            <Banner>
                <h2>Things you should know</h2>
                <p><span style={{fontWeight: 'bold'}}>Precertification Requirements</span>
                    <br/>Precertification RequirementsSee precertification list below for list of services that require precertification.
                    <p>Please contact Accolade Provider Services to confirm which procedure codes related to medications, injections and infusions (including chemotherapy) require precertification.</p>
                </p>
            </Banner>
            <div className={classes.Global}>
                <Card style={styles.container(isDesktop)}>
                    <h1>Lighthouse Autism Center & Missing Piece Accolade Precertification List - effective
                        1/1/2024 </h1>
                    <p>Precertification is a determination of medical necessity only, and does not involve matters of
                        claim payment, eligibility, coverage and the type and/or availability of benefits.
                    </p>
                    <p>The establishment and construct of a precertification list is solely the responsibility of
                        customer and/or the applicable plan sponsor (and not Accolade). The design and implementation of
                        a precertification list should be made only after obtaining the advice of customer’s legal and
                        benefit professionals, to include customer’s full review of applicable health and welfare
                        benefit plan terms and conditions as well as any applicable laws (for example, the Employee
                        Retirement Income Security Act of 1974, and the Health Parity and Addiction Equity Act of 2008,
                        as amended). Accolade will have no responsibility for reviewing applicable plan documents,
                        advising customer with respect to its precertification list or for customer’s compliance with
                        applicable laws related to the same. No representation or warranty is given with respect to this
                        document, which is offered as-is.</p>

                    <div style={{fontWeight: 'bold', marginTop: '4rem'}}>
                        Precertification requirements should be implemented only for those services listed within the
                        Summary Plan Description as requiring Prenotification or Precertification and not defined as
                        excluded.
                    </div>

                    <Accordion label='All Inpatient Admissions'>
                        <ul>
                            <li>Acute</li>
                            <li>Long-Term Acute Care</li>
                            <li>Rehabilitation</li>
                            <li>Mental Health / Substance Use Disorder</li>
                            <li>Transplant</li>
                            <li>Skilled Nursing Facility</li>
                            <li>Residential Treatment Facility</li>
                            <li>Obstetric – Prenotification only (precertification only required if days exceed Federal
                                mandate)
                            </li>
                        </ul>
                    </Accordion>
                    <div style={{fontWeight: 'bold', marginTop: '1rem'}}>Precertification for Inpatient and Outpatient
                        procedures that could be considered Experimental / Investigational
                    </div>
                    <Accordion label='Outpatient and Physician – Surgery'>
                        <ul>
                            <li>All joint replacement surgeries</li>
                            <li>Thyroidectomy, Partial or Complete</li>
                            <li>Prostate or Ovary Removal – include Open Prostatectomy / Oopherectomy</li>
                            <li>Back Surgeries and hardware related to surgery</li>
                            <li>Osteochondral Allograft, knee</li>
                            <li>Hysterectomy (including prophylactic)</li>
                            <li>Autologous chondrocyte implantation, Carticel</li>
                            <li>Transplant (excluding cornea)</li>
                            <li>Balloon sinuplasty</li>
                            <li>Sleep apnea related surgeries, limited to:
                                <ul>
                                    <li>Radiofrequency ablation (Coblation, Somnoplasty)</li>
                                    <li>Uvulopalatopharyngoplasty (UPPP) (including laser-assisted procedures)</li>
                                </ul>
                            </li>
                            <li>Potentially Cosmetic Procedures, including but not limited to:
                                <ul>
                                    <li>Abdominoplasty</li>
                                    <li>Blepharoplasty</li>
                                    <li>Cervicoplasty (neck lift)</li>
                                    <li>Facial skin lesions (Photo therapy, laser therapy - excluding MOHS)</li>
                                    <li>Hernia repair, abdominal and incisional (only when associated with a cosmetic
                                        procedure)
                                    </li>
                                    <li>IDET (thermal intradiscal procedures)</li>
                                    <li>Liposuction/lipectomy</li>
                                    <li>Mammoplasty, augmentation and reduction (including removal of implant)</li>
                                    <li>Mastectomy (including gynecomastia and prophylactic)</li>
                                    <li>Morbid obesity procedures</li>
                                    <li>Orthognathic procedures (e.g. Genioplasty, LeFort osteotomy, Mandibular ORIF,
                                        TMJ)
                                    </li>
                                    <li>Otoplasty</li>
                                    <li>Panniculectomy</li>
                                    <li>Rhinoplasty</li>
                                    <li>Rhytidectomy</li>
                                    <li>Scar revisions</li>
                                    <li>Septoplasty</li>
                                    <li>Varicose vein surgery/sclerotherapy</li>
                                </ul>
                            </li>
                        </ul>
                    </Accordion>
                    <Accordion label='Outpatient and Physician – Diagnostic Services'>
                        <ul>
                            <li>MRI</li>
                            <li>MRA</li>
                            <li>PET</li>
                            <li>Capsule endoscopy</li>
                            <li>Genetic Testing (including BRCA)</li>
                            <li>Sleep Study (facility based)</li>
                        </ul>
                    </Accordion>
                    <Accordion label='Outpatient and Physician – Continuing Care Services'>
                        <ul>
                            <li><strong>Prenotification for the following:</strong>
                                <ul>
                                    <li>Dialysis</li>
                                </ul>
                            </li>
                            <li><strong>Precertification for the following:</strong>
                                <ul>
                                    <li>Radiation Therapy</li>
                                    <li>Hyperbaric Oxygen</li>
                                    <li>Home Health Care related to occupational therapy, physical therapy, speech
                                        therapy and skilled nursing care done in the home
                                    </li>
                                    <li>Durable Medical Equipment, limited to electric/motorized scooters or wheelchairs
                                        and pneumatic compression devices
                                    </li>
                                    <li>DME {'>'}$1k (excludes disposable supplies)</li>
                                </ul>
                            </li>
                        </ul>
                    </Accordion>
                    <Accordion
                        label='Medications, Injections and Infusions (covered under the medical plan) – includes Chemotherapy '>
                        <ul>
                            <li>Please contact Accolade Provider Services at 866-336-0796 to confirm which procedure
                                codes related to medications, injections and infusions (including chemotherapy) require
                                precertification.
                            </li>
                        </ul>
                    </Accordion>
                    <Accordion label='Medical Evacuation'>
                        <ul>
                            <li>Air Ambulance for non-emergent transportation</li>
                        </ul>
                    </Accordion>
                </Card>
                <h1>Ready to start a new pre-certification request?</h1>
                <Button onClick={() => {
                    window.location.href = 'https://accolade.getprecert.com/(S(eusdojxlmjab0fv1zca1xf55))/default.aspx';
                }}
                        style={{cursor: 'pointer'}}
                        id={"start"}>Start Request</Button>
            </div>
        </Aux>
    )
}

const styles = {
    container: isDesktop => ({
        padding: isDesktop ? '80px 170px' : '10px 40px 30px 29px',
        marginBottom: isDesktop ? '150px' : '90px'
    })
};

export default LighthouseAutismCenter;
