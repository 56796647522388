import React from 'react'
import Aux from '../../hoc/Aux-component'
import Header from '../../components/Header/Header'
import Footer from '../Footer/Footer'
import ImgBanner from "../ImgBanner/ImgBanner";
import {PARTNERS_AT_ROUTES, PHONE_NUMBERS} from "../../constants";

const Layout = ({currentRoute, children}) => {
    const partner = PARTNERS_AT_ROUTES[currentRoute];
    const phoneNumber = PHONE_NUMBERS[partner];

    return (
        <Aux>
            <Header currentRoute={currentRoute}/>
            <main>
                <ImgBanner phoneNumber={phoneNumber}/>
                {children}
            </main>
            <Footer phoneNumber={phoneNumber}/>
        </Aux>
    )
};

export default Layout
