import React, {useState} from 'react';
import classes from './Accordion.module.css'
import {ReactComponent as PlusIcon} from '../../assets/svgs/Plus-blue-icon.svg'
import {ReactComponent as MinusIcon} from '../../assets/svgs/Minus-blue-icon.svg'

export default function Accordion({label, subLabel, children}) {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => setIsOpen(!isOpen);

    return (<div className={classes.Accordion}>
        <div className={classes.AccordionHeader} onClick={handleClick}>
            <div>{label} {subLabel && (
            <> 
            <br/>
            <span>{subLabel}</span>
            </>
            )}</div>
            {isOpen ? (
                <div><MinusIcon className={classes.Icon}/></div>
            ) : (
                <div><PlusIcon className={classes.Icon}/></div>
            )}
        </div>
        {isOpen && (
            <div className={classes.AccordionBody}>
                {children}
            </div>
        )}
    </div>)
}
