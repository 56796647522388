import React from 'react'
import classes from './Footer.module.css'
import { ReactComponent as PhoneIcon } from '../../assets/svgs/phone-call.svg'

const Footer = ({ phoneNumber }) => {
    return (
        <footer className={classes.Footer}>
            <div className={classes.ServicePhone}>
                <a href={`tel:${phoneNumber}`} className={classes.PhoneIconLink}><PhoneIcon /></a>
                <div style={{fontWeight:'bold'}}>Accolade Provider Services <br /> <a href={`tel:${phoneNumber}`} style={{ color: 'white' }}>{phoneNumber}</a></div>
            </div>
        </footer>
    )

}

export default Footer
