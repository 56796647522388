import React, {useEffect} from 'react'
import Card from '../../hoc/Card/Card'
import Button from '../../hoc/Button/Button'
import Aux from '../../hoc/Aux-component'
import Banner from '../../hoc/Banner/Banner'
import classes from '../../Global.module.css'
import Accordion from '../../components/Accordion/Accordion'
import {useMediaQuery} from '../../hooks'

const Kindred = () => {

    const isDesktop = useMediaQuery('(min-width: 768px)');

    useEffect(() => {
        document.title = "Precert Accolade | Kindred"
      }, []);
      return(
    <Aux>
        <Banner>
            <h2>Things you should know</h2>
            <p><span style={{ fontWeight: 'bold' }}>COVID-19 impact on pre-certification timeframes</span> <br /> For prior
                approved cases for elective procedures and surgeries that have been postponed, please call 877-236-8782
                when the service is rescheduled.</p>
            <p><span style={{ fontWeight: 'bold' }}>COE / 2nd Opinion Requirement</span> <br /> Kindred at Home requires
                some procedures to be performed at a Center of Excellence (COE) or require a 2nd opinion if not at a
                COE.  <br/> Please have your patient call Accolade for additional benefit information regarding this program.
            </p>
        </Banner>
        <div className={classes.Global}>
            <Card style={styles.container(isDesktop)}>
                <h1>Kindred at Home <br /> Pre-certification List</h1>
                <p>Pre-certification is a determination of medical necessity only, and <span>does not involve matters of claim payment, eligibility, coverage and the type and/or availability of benefits.</span>
                </p>
                <p>The establishment and construct of a pre-certification list is solely the responsibility of customer
                and/or the applicable plan sponsor (and not Accolade). The design and implementation of a
                pre-certification list should be made only after obtaining the advice of customer’s legal and
                benefit professionals, to include customer’s full review of applicable health and welfare benefit
                plan terms and conditions as well as any applicable laws (for example, the Employee Retirement
                Income Security Act of 1974, and the Health Parity and Addiction Equity Act of 2008, as amended).
                Accolade will have no responsibility for reviewing applicable plan documents, advising customer with
                respect to its pre-certification list or for customer’s compliance with applicable laws related to
                the same. No representation or warranty is given with respect to this document, which is offered
                    as-is.</p>
                <div style={{ fontWeight: 'bold' }}>Precertification for Inpatient and Outpatient procedures that could be
                considered Experimental / Investigational
                </div>
                <Accordion label='All Inpatient Admissions'>
                    <ul>
                        <li>Acute</li>
                        <li>Long-Term Acute Care</li>
                        <li>Rehabilitation</li>
                        <li> Mental Health / Substance Use Disorder</li>
                        <li>Transplant</li>
                        <li>Skilled Nursing Facility</li>
                        <li>Residential Treatment Facility</li>
                        <li>Obstetric – Prenotification only (precertification only required if days exceed Federal mandate)</li>
                        <li>Cardiac surgery (limited to the following listed below):
                            <ul>
                                <li>CABG</li>
                                <li>Valve procedures using traditional open-heart surgical methods</li>
                                <li>Endovascular Intervention, Iliac and Femoral Popliteal</li>
                                <li>Endovascular Repair (EVR), Thoracic Aorta</li>
                            </ul>
                        </li>
                        <li>Spine surgeries and hardware related to surgery</li>
                        <li>Joint replacements for hip/knee</li>
                        <li>Transplants including stem cell</li>
                        <li>Oncology care</li>
                    </ul>
                </Accordion>
                <Accordion label='Inpatient or Outpatient Procedures'>
                    <ul>
                        <li>Procedures that could be considered experimental / investigational</li>
                        <li>Benign Uterine Conditions (Non-Cancer)
                            <ul>
                                <li>Endometrial ablation</li>
                                <li>Hysterectomy</li>
                                <li>Myomectomy</li>
                                <li>Uterine artery embolization</li>
                                <li>Uterine fibroid embolization</li>
                            </ul>
                        </li>
                        <li>Carpal tunnel release</li>
                        <li>Cholecystectomy</li>
                        <li>Prostate (Non-Cancer)
                            <ul>
                                <li>Laser enucleation (HoLEP and ThuLEP)</li>
                            </ul>
                        </li>
                        <li>Microwave thermotherapy (TUMT)</li>
                        <li>Photoselection vaporization (PVP)</li>
                        <li>Plasma vaporization (”Button procedure”)</li>
                        <li>Prostatectomy</li>
                        <li>Prostatic urethral lift (PUL)</li>
                        <li>Transurethral incision of the prostate (TUIP)</li>
                        <li>Transurethral resection of the prostate (TURP)</li>
                        <li>Water vapor energy ablation (WAVE)</li>
                    </ul>
                </Accordion>
                <Accordion label='Outpatient and Physician – Continuing Care Services'>
                    <ul>
                        <li>Pre-notification for the following:
                            <ul>
                                <li> Biopsies (excluding skin)</li>
                                <li>Vascular Access Devices for the Infusion of Chemotherapy (e.g. PICC and Central Lines)</li>
                            </ul>
                        </li>
                        <li>Pre-certification for the following:
                            <ul>
                                <li>All joint replacement surgeries</li>
                                <li>Thyroidectomy, Partial or Complete</li>
                                <li>Prostate or Ovary Removal – include Open Prostatectomy / Opherectomy</li>
                                <li>Back Surgeries and hardware related to surgery</li>
                                <li>Spinal procedures and hardware related to surgery</li>
                                <li>Thyroidectomy, Partial or Complete</li>
                                <li>Osteochondral Allograft, knee</li>
                                <li>Hysterectomy (including prophylactic)</li>
                                <li>Autologous chondrocyte implantation, Carticel</li>
                                <li>Transplant (excluding cornea)</li>
                                <li>Sleep apnea related surgeries, limited to:
                                    <ul>
                                        <li> Radiofrequency ablation (Coblation, Somnoplasty)</li>
                                        <li>Uvulopalatopharyngoplasty (UPPP) (including laser-assisted procedures)</li>
                                    </ul>
                                </li>
                                <li>Potentially Cosmetic Procedures, including but not limited to:
                                <ul>
                                        <li>Radiofrequency ablation (Coblation, Somnoplasty)</li>
                                        <li>Uvulopalatopharyngoplasty (UPPP) (including laser-assisted procedu</li>
                                    </ul>
                                </li>
                                <li>Potentially Cosmetic Procedures, including but not limited to:
                                    <ul>
                                        <li>Abdominoplasty</li>
                                        <li>Blepharoplasty</li>
                                        <li>Cervicoplasty (neck lift)</li>
                                        <li>Facial skin lesions (Photo therapy, laser therapy - excluding MOHS)</li>
                                        <li>Hernia repair, abdominal and incisional (only when associated with a cosmetic procedure)</li>
                                        <li>IDET (thermal intradiscal procedures)</li>
                                        <li>Liposuction/lipectomy</li>
                                        <li>Mammoplasty, augmentation and reduction (including removal of implant)</li>
                                        <li>Mastectomy (including gynecomastia and prophylactic)</li>
                                        <li>Orthognathic procedures (e.g. Genioplasty, LeFort osteotomy, Mandibular ORIF, TMJ)</li>
                                        <li>Otoplasty</li>
                                        <li>Panniculectomy</li>
                                        <li>Rhinoplasty Rhytidectomy</li>
                                        <li>Scar revisions</li>
                                        <li>Septoplasty</li>
                                        <li>Varicose vein surgery/sclerotherapy</li>
                                        <li>Cardiac surgery (limited to procedures listed below):</li>
                                        <ul>
                                            <li>Endovascular Intervention, Iliac and Femoral Popliteal</li>
                                            <li>Endovascular Repair (EVR), Thoracic Aorta</li>
                                            <li>Saphenous Vein Ablation, Laser</li>
                                            <li>Saphenous Vein Ablation, Radiofrequency</li>
                                            <li>Saphenous Vein Stripping</li>
                                        </ul>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>Microwave thermotherapy (TUMT)</li>
                        <li>Photoselection vaporization (PVP)</li>
                        <li>Plasma vaporization (”Button procedure”)</li>
                        <li>Prostatectomy Prostatic urethral lift (PUL)</li>
                        <li>Transurethral incision of the prostate (TUIP)</li>
                        <li>Transurethral resection of the prostate (TURP)</li>
                        <li>Water vapor energy ablation (WAVE)</li>
                    </ul>
                </Accordion>
                <Accordion label='Outpatient and Physician – Diagnostic Services'>
                    <ul>
                        <li>Chemotherapy (including oral)</li>
                        <li>Radiation Therapy</li>
                        <li>Oncology care including oncology and transplant related injections, infusions and treatments (e.g. CAR-T, endocrine and immunotherapy), excluding supportive drugs (e.g. antiemetic and antihistamine)</li>
                        <li>Hyperbaric Oxygen</li>
                        <li>Home Health Care</li>
                        <li>Durable Medical Equipment, limited to electric/motorized scooters or wheelchairs and pneumatic compression devices</li>
                        <li>DME &gt;$1K</li>
                        <li>Transplant care including stem cell</li>
                    </ul>
                </Accordion>
            </Card>
            <h1>Ready to start a new pre-certification request?</h1>
            <Button onClick={() => {
                window.location.href = 'https://accolade.getprecert.com/(S(cbykgvb0qn2hh32kfks0etan))/default.aspx';
            }}
                style={{ cursor: 'pointer' }}
                id={"start"}>Start Request</Button>
        </div>
    </Aux>
)}

const styles = {
    container: isDesktop => ({
        padding: isDesktop ? '80px 170px':'10px 40px 30px 29px', 
        marginBottom: isDesktop ? '150px':'90px'
    })
  };

export default Kindred;
