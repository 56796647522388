import React, {useEffect} from 'react'
import Card from '../../hoc/Card/Card'
import Banner from '../../hoc/Banner/Banner'
import Button from '../../hoc/Button/Button'
import Aux from '../../hoc/Aux-component'
import classes from '../../Global.module.css'
import Accordion from '../../components/Accordion/Accordion'
import {useMediaQuery} from '../../hooks'

const SevenEleven = () => {

    const isDesktop = useMediaQuery('(min-width: 768px)');

    useEffect(() => {
        document.title = "Precert Accolade | 7-Eleven"
      }, []);
      return(
    <Aux>
        <Banner>
            <h2>Things you should know</h2>
            <p><span style={{ fontWeight: 'bold' }}>Surgery Plus Centers of Excellence Mandate</span> <br /> The following procedures must be performed at a Surgery Plus COE - Bariatric, Joint, and Spine Procedures. Please contact Accolade Provider Services at 866-417-0163 for more information.</p>
            <p><span style={{ fontWeight: 'bold' }}>MRI for MSK conditions</span> <br /> For certain MSK conditions, the member is required to have 3 PT visits with IMC (Vendor) before having an MRI or there will a $500 penalty to the member. Please have the member contact Accolade Member Services at 866-336-0735 to understand this penalty. You can still proceed with precertification request for the MRI if desired by clicking on the link below or calling Accolade Provider Services at 866-417-0163.</p>
            <p><span style={{ fontWeight: 'bold' }}>2nd Opinion for MSK Surgery</span> <br /> For specific MSK surgeries, the member is required to have a second opinion with Accolade 2nd Opinion before having surgery or there will a $500 penalty to the member. Please have the member contact Accolade Member Services at 866-336-0735 to understand this penalty. You can still proceed with the precertification process for the surgery if desired by clicking on the link below or calling Accolade Provider Services at 866-417-0163.</p>
            <p><span style={{ fontWeight: 'bold' }}>UM Carve Out Services</span> <br /> High-Cost Medications (including infusions, injections, chemotherapy including oral medications) must be submitted to VIVIO for review. Please  call VIVIO at 800-470-4034 to initiate precertification.</p>
            <p><span style={{ fontWeight: 'bold' }}>Precertification Requirements</span> <br /> See precertification list below for list of services that require precertification.</p>
        </Banner>
        <div className={classes.Global}>
            <Card style={styles.container(isDesktop)}>
                <h1>7-Eleven Precertification List <br />Effective 8/15/24</h1>
                <p>Pre-certification is a determination of medical necessity only, and <span>does not involve matters of claim payment, eligibility, coverage and the type and/or availability of benefits.</span>
                </p>
                <p>The establishment and construct of a precertification list is solely the responsibility of customer and/or the applicable plan sponsor (and not Accolade).
                The design and implementation of a precertification list should be made only after obtaining the advice of customer’s legal and benefit professionals,
                to include customer’s full review of applicable health and welfare benefit plan terms and conditions as well as any applicable laws (for example, the Employee Retirement Income Security Act of 1974, and the Health Parity and Addiction Equity Act of 2008, as amended).
                Accolade will have no responsibility for reviewing applicable plan documents, advising customer with respect to its precertification list or for customer’s compliance with applicable laws related to the same.
                No representation or warranty is given with respect to this document, which is offered as-is.</p>
                <div style={{ fontWeight: 'bold', marginBottom: '12px' }}>Precertification  requirements  should  be  implemented  only  for  those  services  listed  within  the  Summary  Plan Description as requiring Prenotification or Precertification and not defined as excluded.</div>
                <div style={{ fontWeight: 'bold', textDecoration: 'underline' }}>UM Carve Out</div>
                <div style={{ fontWeight: 'bold' }}>High Cost Medications (infusions, injections and chemotherapy) - if medication is on ‘VIVIO High Cost Durg List” - refer to VIVIO at 800-470-4034</div>
                <Accordion label='All Inpatient Admissions'>
                    <ul>
                        <li>Acute</li>
                        <li>Long-Term Acute Care</li>
                        <li>Rehabilitation</li>
                        <li>Mental Health / Substance Use Disorder</li>
                        <li>Transplant</li>
                        <li>Skilled Nursing Facility</li>
                        <li>Residential Treatment Facility</li>
                        <li>Obstetric – Prenotification only (precertification only required if days exceed Federal mandate)</li>
                    </ul>
                </Accordion>
                <div style={{ fontWeight: 'bold' }}>Precertification for Inpatient and Outpatient procedures that could be considered Experimental / Investigational</div>
                <Accordion label='Outpatient and Physician – Surgery' subLabel={'SurgeryPlus COE'}>
                    <p>(services must be approved by and rendered by SurgeryPlus) Limited to specific CPT codes; reference Surgery Plus Carve Out List</p>
                    <ul>
                        <li>Bariatric</li>
                        <ul>
                            <li>Duodenal Switch</li>
                            <li>Gastric Bypass</li>
                            <li>Lap Band</li>
                            <li>Sleeve Gastrectomy</li>
                        </ul>
                        <li>Joint Replacement</li>
                        <ul>
                            <li>Hip</li>
                            <li>Knee</li>
                            <li>Wrist</li>
                            <li>Ankle</li>
                            <li>Elbow</li>
                            <li>Shoulder</li>
                        </ul>
                        <li>Spine</li>
                        <ul>
                            <li>Spinal Fusion</li>
                            <li>Cervical Disc Fusion</li>
                            <li>Laminectomy</li>
                            <li>Laminotomy</li>
                            <li>Lumbar Interbody Fusion
                            </li>
                        </ul>
                    </ul>
                </Accordion>
                <Accordion label='Outpatient and Physician – Surgery' subLabel={'UM by Accolade'}>
                    <ul>
                        <li>Procedures not included on the SurgeryPlus CPT code list for the following surgeries:</li>
                        <ul>
                                <li>Bariatric</li>
                            </ul>
                        <li>All joint replacement surgeries</li>
                        <li>Thyroidectomy, Partial or Complete</li>
                        <li>Prostate or Ovary Removal – include Open Prostatectomy / Oopherectomy</li>
                        <li>Back Surgeries and hardware related to surgery</li>
                        <li>Osteochondral Allograft, knee</li>
                        <li>Hysterectomy (including prophylactic)</li>
                        <li>Autologous chondrocyte implantation, Carticel</li>
                        <li>Transplant (excluding cornea)</li>
                        <li>Balloon sinuplasty</li>
                        <li>Sleep apnea related surgeries, limited to:
                            <ul>
                                <li>Radiofrequency ablation (Coblation, Somnoplasty)</li>
                                <li>Uvulopalatopharyngoplasty (UPPP) (including laser-assisted procedures)</li>
                            </ul>
                        </li>
                        <li>Potentially Cosmetic Procedures, including but not limited to:
                            <ul>
                                <li>Abdominoplasty</li>
                                <li>Blepharoplasty</li>
                                <li>Cervicoplasty (neck lift)</li>
                                <li>Facial skin lesions (Photo therapy, laser therapy - excluding MOHS)</li>
                                <li>Hernia repair, abdominal and incisional (only when associated with a cosmetic procedure)</li>
                                <li>IDET (thermal intradiscal procedures)</li>
                                <li>Liposuction/lipectomy</li>
                                <li>Mammoplasty, augmentation and reduction (including removal of implant)</li>
                                <li>Mastectomy (including gynecomastia and prophylactic)</li>
                                <li>Morbid obesity procedures</li>
                                <li>Orthognathic procedures (e.g. Genioplasty, LeFort osteotomy, Mandibular ORIF, TMJ)</li>
                                <li>Otoplasty</li>
                                <li>Panniculectomy</li>
                                <li>Rhinoplasty</li>
                                <li>Rhytidectomy</li>
                                <li>Scar revisions</li>
                                <li>Septoplasty</li>
                                <li>Varicose vein surgery/sclerotherapy</li>
                            </ul>
                        </li>
                    </ul>
                </Accordion>
                <Accordion label='Outpatient and Physician – Diagnostic Services'>
                    <ul>
                        <li>MRI</li>
                        <li>MRA</li>
                        <li>PET</li>
                        <li>Capsule endoscopy</li>
                        <li>Genetic Testing (including BRCA)</li>
                        <li>Sleep Study (facility based)</li>
                    </ul>
                </Accordion>
                <Accordion label='Outpatient and Physician – Continuing Care Services'>
                    <ul>
                        <li>Radiation Therapy</li>
                        <li>Hyperbaric Oxygen</li>
                        <li>Home Health Care related to occupational therapy, physical therapy, speech therapy and skilled nursing care done in the home</li>
                        <li>Durable Medical Equipment, limited to electric/motorized scooters or wheelchairs and pneumatic compression devices</li>
                        <li>DME {'>'}$1K (excludes disposable supplies)</li>
                    </ul>
                </Accordion>
                <Accordion label='Medical Evacuation'>
                    <ul>
                        <li>Air Ambulance for non-emergent transportation</li>
                    </ul>
                </Accordion>
            </Card>
            <h1>Ready to start a new pre-certification request?</h1>
            <Button onClick={() => {
                window.location.href = 'https://accolade.getprecert.com/(S(cbykgvb0qn2hh32kfks0etan))/default.aspx';
            }}
                style={{ cursor: 'pointer' }}
                id={"start"}>Start Request</Button>
        </div>
    </Aux>
)}

const styles = {
    container: isDesktop => ({
        padding: isDesktop ? '80px 170px':'10px 40px 30px 29px', 
        marginBottom: isDesktop ? '150px':'90px'
    })
  };
  
export default SevenEleven;
