import React from 'react'
import Aux from '../Aux-component'
import classes from './Banner.module.css'

const Banner = ({children, style}) => (
    <Aux>
        <div className={classes.Banner}  style={style}>
            {children}
        </div>
    </Aux>
);

export default Banner
